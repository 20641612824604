import React from 'react';
import Layout from '../../components/layout';

const Impressum = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Impressum" noSlider {...props}>
			<div>
				<p>(nach §5 Telemediengesetz TMG)</p>
				<p>
					<b>Informationsanbieter</b>
					<br />
					(verantwortlich für den Inhalt)
				</p>
				<p>
					<b>
						Praxis der Dermatologie & Aesthetic
						<br />
						Dr. med. Krokowski
					</b>
					<br />
					Kölnische Straße 5<br />
					34117 Kassel
				</p>
				<p>
					Telefon (05 61) 7 03 69 55
					<br />
					Telefax (05 61) 7 03 46 24
					<br />
					info [at] krokowski-dermatologie.de
				</p>
				<b>Verantworlich für den Inhalt:</b> Dr. med. Michael Krokowski
				<div>
					Gesetzl. Berufsbezeichnung (verliehen in): Facharzt für
					Dermatologie (Deutschland) <br />
					Zuständige Kammer: Ärztekammer Hessen, Web:
					http://www.laekh.de <br />
					Es gilt die Berufsordnung der oben genannten Kammer <br />
					<br />
					Zulassungsbehörde: Kassenärztliche Vereinigung Hessen
					<br />
					Zulassungsnummer der Praxis: 421 28 3400
					<br />
					Zulassungsnummer Dr. med. M. Krokowski: 938 68 5421
				</div>
				<div>&nbsp;</div>
				Die hier gegebenen Informationen erfolgten zur Erfüllung
				nachfolgend genannter gesetzlicher Verpflichtungen:
				<ul>
					<li>§ 5 und § 6 Telemediengesetz (TMG)</li>
					<li>§4 Abs. 3 Bundesdatenschutzgesetz (BDSG)</li>
					<li>§ 312c Bürgerliches Gesetzbuch (BGB)</li>
					<li>
						§ 1 BGB-Informationspflichten-Verordnung (BGB-InfoV)
					</li>
				</ul>
				<p>
					<b>
						<br />
						Haftungsausschluss
					</b>
					<br />
					<i>1. Inhalt des Onlineangebotes</i>
					<br />
					Der Autor übernimmt keinerlei Gewähr für die Aktualität,
					Korrektheit, Vollständigkeit oder Qualität der
					bereitgestellten Informationen. Haftungsansprüche gegen den
					Autor, welche sich auf Schäden materieller oder ideeller Art
					beziehen, die durch die Nutzung oder Nichtnutzung der
					dargebotenen Informationen bzw. durch die Nutzung
					fehlerhafter und unvollständiger Informationen verursacht
					wurden, sind grundsätzlich ausgeschlossen, sofern seitens
					des Autors kein nachweislich vorsätzliches oder grob
					fahrlässiges Verschulden vorliegt.
					<br />
					Alle Angebote sind freibleibend und unverbindlich. Der Autor
					behält es sich ausdrücklich vor, Teile der Seiten oder das
					gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu
					ergänzen, zu löschen oder die Veröffentlichung zeitweise
					oder endgültig einzustellen.
					<br />
					<br />
					<i>2. Verweise und Links</i>
					<br />
					Bei direkten oder indirekten Verweisen auf fremde
					Internetseiten ("Links"), die außerhalb des
					Verantwortungsbereiches des Autors liegen, würde eine
					Haftungsverpflichtung ausschließlich in dem Fall in Kraft
					treten, in dem der Autor von den Inhalten Kenntnis hat und
					es ihm technisch möglich und zumutbar wäre, die Nutzung im
					Falle rechtswidriger Inhalte zu verhindern.
					<br />
					Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt
					der Linksetzung keine illegalen Inhalte auf den zu
					verlinkenden Seiten erkennbar waren. Auf die aktuelle und
					zukünftige Gestaltung, die Inhalte oder die Urheberschaft
					der gelinkten/verknüpften Seiten hat der Autor keinerlei
					Einfluss. Deshalb distanziert er sich hiermit ausdrücklich
					von allen Inhalten aller gelinkten /verknüpften Seiten, die
					nach der Linksetzung verändert wurden. Diese Feststellung
					gilt für alle innerhalb des eigenen Internetangebotes
					gesetzten Links und Verweise sowie für Fremdeinträge in vom
					Autor eingerichteten Gästebüchern, Diskussionsforen und
					Mailinglisten. Für illegale, fehlerhafte oder unvollständige
					Inhalte und insbesondere für Schäden, die aus der Nutzung
					oder Nichtnutzung solcherart dargebotener Informationen
					entstehen, haftet allein der Anbieter der Seite, auf welche
					verwiesen wurde, nicht derjenige, der über Links auf die
					jeweilige Veröffentlichung lediglich verweist.
					<br />
					<br />
					<i>3. Urheber- und Kennzeichenrecht</i>
					<br />
					Der Autor ist bestrebt, in allen Publikationen die
					Urheberrechte der verwendeten Grafiken, Tondokumente,
					Videosequenzen und Texte zu beachten, von ihm selbst
					erstellte Grafiken, Tondokumente, Videosequenzen und Texte
					zu nutzen oder auf lizenzfreie Grafiken, Tondokumente,
					Videosequenzen und Texte zurückzugreifen.
					<br />
					Alle innerhalb des Internetangebotes genannten und ggf.
					durch Dritte geschützten Marken- und Warenzeichen
					unterliegen uneingeschränkt den Bestimmungen des jeweils
					gültigen Kennzeichenrechts und den Besitzrechten der
					jeweiligen eingetragenen Eigentümer. Allein aufgrund der
					bloßen Nennung ist nicht der Schluss zu ziehen, dass
					Markenzeichen nicht durch Rechte Dritter geschützt sind!
					<br />
					Das Copyright für veröffentlichte, vom Autor selbst
					erstellte Objekte bleibt allein beim Autor der Seiten. Eine
					Vervielfältigung oder Verwendung solcher Grafiken,
					Tondokumente, Videosequenzen und Texte in anderen
					elektronischen oder gedruckten Publikationen ist ohne
					ausdrückliche Zustimmung des Autors nicht gestattet.
					<br />
					<br />
					<i>4. Datenschutz</i>
					<br />
					Sofern innerhalb des Internetangebotes die Möglichkeit zur
					Eingabe persönlicher oder geschäftlicher Daten
					(Emailadressen, Namen, Anschriften) besteht, so erfolgt die
					Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich
					freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller
					angebotenen Dienste ist - soweit technisch möglich und
					zumutbar - auchohne Angabe solcher Daten bzw. unter Angabe
					anonymisierter Daten oder eines Pseudonyms gestattet. Die
					Nutzung der im Rahmen des Impressums oder vergleichbarer
					Angaben veröffentlichten Kontaktdaten wie Postanschriften,
					Telefon- und Fax-nummern sowie Emailadressen durch Dritte
					zurÜbersendungvon nicht ausdrücklich angeforderten
					Informationen ist nicht gestattet. Rechtliche Schritte gegen
					die Versender von sogenannten Spam-Mails bei Verstössen
					gegen dieses Verbot sind ausdrücklich vorbehalten.
					<br />
					<br />
					<i>5. Rechtswirksamkeit dieses Haftungsausschlusses</i>
					<br />
					Dieser Haftungsausschluss ist als Teil des Internetangebotes
					zu betrachten, von dem aus auf diese Seite verwiesen wurde.
					Sofern Teile oder einzelne Formulierungen dieses Textes der
					geltenden Rechtslage nicht, nicht mehr oder nicht
					vollständig entsprechen sollten, bleiben die übrigen Teile
					des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon
					unberührt.
				</p>
				<p>
					<b>Konzeption/Realisation</b>
				</p>
				<p>
					Ernst-Andreas Krokowski
					<br />
					eakroko@gmail.com
					<br />
					Telefon (05 61) 58580616
				</p>
			</div>
		</Layout>
	);
};

export default Impressum;
